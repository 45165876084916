import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"

const auth = getAuth();

/**
 * Authenticate on page levels
 * @param { React.Dispatch<React.SetStateAction<{username: string;url: string;}>>} setter
 * @param {Location} location
 * @param {NavigateFunction} navigate
 * @return {void}
 */
export const authenticateUser = (setter, location, navigate) => {
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            setter({
                username: sessionStorage.getItem("user_name"),
                url: sessionStorage.getItem("user_url")
            })
            if (location.state) {
                setter({
                    username: location.state.name,
                    url: location.state.displayPic
                })
            }
            
            const idToken = await user?.getIdToken(false);

            sessionStorage.setItem('id_token', idToken);
            sessionManager.listenForActivity(navigate);
        } else {
            sessionStorage.clear()
            navigate("/")
        }
    })
}

const sessionManager = {
    listenStatus: false,
    lastActivity: Date.now(),
    sessionTimer: null,

    listenForActivity(navigate) {
        if (!this.listenStatus) {
            const debouncedResetTimer = this.debounce(() => this.resetTimer(navigate), 1000);
            window.addEventListener("click", debouncedResetTimer);
            window.addEventListener("keydown", debouncedResetTimer);
            this.startSessionTimer(navigate);
            this.listenStatus = true;
        }
    },

    debounce(func, delay) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(this, args), delay);
        };
    },

    resetTimer(navigate) {
        this.lastActivity = Date.now();
        // console.log(`Last Activity: ${new Date(this.lastActivity).toLocaleString()}`);
        clearTimeout(this.sessionTimer);
        this.startSessionTimer(navigate);
    },

    startSessionTimer(navigate) {
        this.sessionTimer = setTimeout(() => {
            // alert("Session expired due to inactivity.");
            this.googleSignout(navigate);
        }, 30 *60 * 1000); // 15 minutes
    },

    googleSignout(navigate) {
        const auth = getAuth();
        signOut(auth).then(() => {
          sessionStorage.clear();
          navigate("/")
        }).catch((error) => {
          alert('Signout error');
        });
      }
};